<template>
  <div class="page-content" v-if="setItem">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Set item detail"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Set Item</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="h-set-name"
              label-cols-md="3"
            >
              <ui-component :component-data="setItem.name" class="border" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="h-description"
              label-cols-md="3"
            >
              <ui-component
                :component-data="setItem.description"
                class="border"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <validation-observer
              ref="detail"
            > 
              <validation-provider
                name="Drop rate buff"
                v-slot="validationContext"
                :rules="{ required: true, min_value: 0}"
              >
                <b-form-group label="Drop rate buff" label-for="h-number" label-cols-md="3">
                  <b-input-group size="lg" prepend="%">
                    <b-form-input
                        v-model="setItem.buff_effect"
                        :state="getValidationState(validationContext)"
                        type="number"
                        aria-describedby="input-buff-effect-feedback"
                      />
                  </b-input-group>
                  <b-form-invalid-feedback id="input-buff-effect-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- <validation-provider
                name="Require parts"
                v-slot="validationContext"
                :rules="{ required: true, min_value: 0}"
              >
                <b-form-group label="Require parts" label-for="h-number" label-cols-md="3">
                  <b-form-input
                      v-model="setItem.require_parts"
                      :state="getValidationState(validationContext)"
                      type="number"
                      aria-describedby="input-require-parts-feedback"
                    />
                  <b-form-invalid-feedback id="input-require-parts-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider> -->
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </section>
    <action-buttons
      update-button
      delete-button
      back-button
      @update="update"
      @delete="deleteSet"
      :is-updating="isUpdating"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import service from '../service'
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      setItem: null,
    }
  },
  computed: {
    setItemId() {
      return this.$route.params.set_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Item Sets List",
          to: { name: "item-sets-list" },
        },
      ];
      items.push({ text: "Item Set Detail", active: true });
      return items;
    },
  },
  created(){
    this.getSetItem()
  },
  methods:{
    async getSetItem() {
      this.setItem = await service.get({ id: this.setItemId });
    },
    async update() {
      let validation = await this.$refs.detail.validate()
      if (!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Validate error",
        });
      }
      else{
        this.isUpdating = true;
        await service.update({
          data: JSON.stringify(this.setItem),
        });
        this.getSetItem();
        this.isUpdating = false;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async deleteSet() {
      await service.delete({ id: this.setItemId });
      this.$router.push({ name: "item-sets-list" });
    },
  }
}
</script>